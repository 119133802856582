import React from 'react';
import { globalConfig } from 'src/globalConfig';
import { SiteTop } from '@/domain/SiteTop';
import MetaTags from '@/shared/components/MetaTags';

export default function Page() {
  return (
    <React.Fragment>
      <MetaTags
        host={globalConfig.HELLOERO}
        title="HELLO ERO powered by H2U"
        description="シンプルに、カジュアルに。アダルトコンテンツを楽しもう。ようこそ、HELLO ERO(ハローエロ)へ。さあ、今すぐログインして、購入済みのコードを使って視聴をスタートしましょう。"
      />
      <SiteTop />
    </React.Fragment>
  );
}
