import { useRouter } from 'next/router';
import React from 'react';
import { useLoginStateContext } from '@/shared/context/LoginStateContext';

type UseTop = {
  isInitialized: boolean;
};

export function useSiteTop(): UseTop {
  const router = useRouter();
  const [isInitialized, setIsInitialized] = React.useState<boolean>(false);
  const { isLoadedUserInfo, userInfo } = useLoginStateContext();

  React.useEffect(() => {
    if (!isLoadedUserInfo) {
      return;
    }

    if (userInfo.isLoggedIn) {
      router.replace('/my-library');
      return;
    }

    setIsInitialized(true);
  }, [isLoadedUserInfo, router, userInfo.isLoggedIn]);

  return {
    isInitialized,
  };
}
